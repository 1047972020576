import Cookies from 'js-cookie';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, map, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './user';
import { PlayFabClient } from '../playfab/client/playfab-client';
import { EmailCredentials } from './email-credentials';
import { SignUpCredentials } from './sign-up-credentials';
import { Guid } from '../guid';
import { LoginResult } from '../playfab/client/login-result';
import { UserAccountInfo } from '../playfab/client/user-account-info';
import { Cookie } from '../cookie';
import { Router } from '@angular/router';
import { encode, decode } from 'js-base64';
import { UNAUTHORIZED } from './auth-errors';
import { INVALID_OPERATION } from '../app-errors';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private currentCid: string;
    private signUpsSource: Subject<User | undefined>;
    private verificationsSource: Subject<void>;

    constructor(
        private readonly playFabClient: PlayFabClient,
        private readonly router: Router,
        private readonly http: HttpClient
    ) {
        this.signUpsSource = new Subject();
        this.verificationsSource = new Subject();
    }

    public get verifications(): Observable<void> {
        return this.verificationsSource.asObservable();
    }

    public get cid(): string | undefined {
        return Cookies.get(`${Cookie.prefix}cid`) ?? this.currentCid;
    }

    public get signUps(): Observable<User | undefined> {
        return this.signUpsSource.asObservable();
    }

    public set cid(value: string | undefined) {
        const name = `${Cookie.prefix}cid`;
        if (!value) {
            Cookies.remove(name);
            return;
        }

        this.currentCid = value;
        Cookies.set(name, value, { secure: true });
    }

    public get activeAccount(): LoginResult | undefined {
        const json = Cookies.get(`${Cookie.prefix}ac`);
        if (!json) {
            return undefined;
        }

        return JSON.parse(decode(json)) as LoginResult;
    }

    public set activeAccount(value: LoginResult | undefined) {
        const name = `${Cookie.prefix}ac`;
        if (!value) {
            Cookies.remove(name);
        } else {
            Cookies.set(`${Cookie.prefix}ac`, encode(JSON.stringify(value)), {
                secure: true,
                expires: 1
            });
        }
    }

    public get userSnapshot(): User | undefined {
        return this.user.value;
    }

    public user: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(
        undefined
    );

    public get isEmailVerified(): Observable<boolean> {
        return this.user.pipe(map(x => this.hasVerifiedEmail(x)));
    }

    public get isAuthenticated(): Observable<boolean> {
        return this.user.pipe(map(x => !!x && !AuthService.checkAuthSource(x, 'CustomId')));
    }

    public get isAnonymous(): Observable<boolean> {
        return this.user.pipe(map(x => !!x && AuthService.checkAuthSource(x, 'CustomId')));
    }

    private static checkAuthSource(user: User, source: string): boolean {
        return user?.account?.TitleInfo?.Origination === source;
    }

    public async signUpWithEmail(credentials: SignUpCredentials): Promise<User | undefined> {
        const token = await this.playFabClient.registerPlayFabUser({
            Email: credentials.email,
            Username: credentials.username,
            Password: credentials.password,
            TitleId: environment.playfab.titleId
        });

        const account = this.getAccountInfo(token);
        const data = this.getPlayerProfile(token);
        const user = {
            login: token,
            account: await account,
            profile: await data
        };

        await this.ensureContactEmailAddress(user);

        this.signUpsSource.next(user);
        this.user.next(user);
        return this.userSnapshot;
    }

    public async loginWithToken() {
        if (!this.activeAccount) {
            this.router.navigate(['/login']);
            return;
        }

        const token = this.activeAccount;
        const account = this.getAccountInfo(token);
        const profile = this.getPlayerProfile(token);
        const user = {
            login: token,
            account: await account,
            profile: await profile
        };

        await this.ensureContactEmailAddress(user);

        this.user.next(user);
        return this.userSnapshot;
    }

    public async loginWithEmail(credentials: EmailCredentials): Promise<User | undefined> {
        const token = await this.playFabClient.loginWithEmail({
            Email: credentials.email,
            Password: credentials.password,
            TitleId: environment.playfab.titleId
        });

        const account = this.getAccountInfo(token);
        const data = this.getPlayerProfile(token);
        const user = {
            login: token,
            account: await account,
            profile: await data
        };

        await this.ensureContactEmailAddress(user);

        this.user.next(user);
        return this.userSnapshot;
    }

    public async loginWithCustomId(): Promise<User | undefined> {
        this.cid = this.cid ? this.cid : Guid.newGuid();
        const token = await this.playFabClient.loginWithCustomId({
            CreateAccount: true,
            CustomId: this.cid,
            TitleId: environment.playfab.titleId
        });

        const account = this.getAccountInfo(token);
        const data = this.getPlayerProfile(token);
        this.user.next({
            login: token,
            account: await account,
            profile: await data
        });

        return this.userSnapshot;
    }

    public logout() {
        this.activeAccount = undefined;
        this.cid = undefined;
        this.user.next(undefined);
        this.router.navigate(['/login']);
    }

    public notifyRegistrationVerified() {
        if (!this.isEmailVerified) {
            console.log('Invalid verification confirmation. User not verified.');
            return;
        }

        this.verificationsSource.next();
    }

    private async getAccountInfo(token: LoginResult): Promise<UserAccountInfo> {
        const result = await this.playFabClient.getAccountInfo(
            {
                PlayfabId: token.PlayFabId
            },
            token
        );

        return result.AccountInfo;
    }

    public async requestEmailVerificationEmail(user: User) {
        const url = `${environment.api.host}/auth/registration/email`;
        return firstValueFrom(
            this.http.post<any>(url, {
                playFabId: user.account.PlayFabId
            })
        );
    }

    public async requestPasswordRecoveryEmail(email: string) {
        const url = `${environment.api.host}/auth/password/recovery`;
        return firstValueFrom(
            this.http.post<any>(url, {
                email: email
            })
        );
    }

    public async resetPassword(password: string, token: string) {
        const url = `${environment.api.host}/auth/password/reset`;
        return firstValueFrom(
            this.http.post<any>(url, {
                password: password,
                token: token
            })
        );
    }

    private async ensureContactEmailAddress(user: User | undefined) {
        if (!user) {
            throw new Error(UNAUTHORIZED);
        }

        const anonymous = AuthService.checkAuthSource(user, 'CustomId');
        if (anonymous) {
            throw new Error(INVALID_OPERATION);
        }

        const verified = this.hasVerifiedEmail(user);
        if (verified) {
            return;
        }

        const count = user?.profile?.ContactEmailAddresses?.length;
        if (!!count && count > 0) {
            return;
        }

        const address = user?.account.PrivateInfo?.Email;
        if (!address) {
            throw new Error('Property Email must not be empty!');
        }

        await this.playFabClient.addOrUpdateContactEmail(
            {
                EmailAddress: address
            },
            user.login
        );
    }

    private async getPlayerProfile(token: LoginResult) {
        const info = await this.playFabClient.getPlayerProfile(
            {
                PlayFabId: token.PlayFabId as string,
                ProfileConstraints: {
                    ShowContactEmailAddresses: true
                }
            },
            token
        );

        return info.PlayerProfile;
    }

    private hasVerifiedEmail(user: User | undefined): boolean {
        if (!user) {
            throw new Error(UNAUTHORIZED);
        }

        return (
            !!user?.profile.ContactEmailAddresses &&
            user.profile?.ContactEmailAddresses.length > 0 &&
            user.profile?.ContactEmailAddresses[0].VerificationStatus === 'Confirmed'
        );
    }
}
