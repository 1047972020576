import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginResult } from './login-result';
import { UpdateUserDataRequest } from './update-user-data-request';
import { UpdateUserDataResult } from './update-user-data-result';
import { GetUserDataRequest } from './get-user-data-request';
import { GetUserDataResult } from './get-user-data-result';
import { environment } from 'src/environments/environment';
import { firstValueFrom, map } from 'rxjs';
import { LoginWithPlayFabRequest } from './login-with-playfab-request';
import { LoginWithEmailRequest } from './login-with-email-request';
import { RegisterPlayFabUserRequest } from './register-playfab-user-request';
import { GetCatalogItemsRequest } from './get-catalog-items-request';
import { GetCatalogItemsResult } from './get-catalog-items-result';
import { LoginWithCustomIdRequest } from './login-with-custom-id-request';
import { GetAccountInfoRequest } from './get-account-info-request';
import { GetAccountInfoResult } from './get-account-info-result';
import { PlayFabResponse } from '../playfab-response';
import { GetStoreItemsResult } from './get-store-items-result';
import { GetStoreItemsRequest } from './get-store-items-request';
import { GetUserInventoryRequest } from './get-user-inventory-request';
import { GetUserInventoryResult } from './get-user-inventory-result';
import { AddOrUpdateContactEmailRequest } from './add-or-update-contact-email-request';
import { AddOrUpdateContactEmailResult } from './add-or-update-contact-email-result';
import { GetPlayerProfileRequest } from './get-player-profile-request';
import { GetPlayerProfileResult } from './get-player-profile-result';

@Injectable({
    providedIn: 'root'
})
export class PlayFabClient {
    public static apiRoute = `https://${environment.playfab.titleId}.playfabapi.com`;

    constructor(private http: HttpClient) {}

    public async updateUserData(
        request: UpdateUserDataRequest,
        login: LoginResult
    ): Promise<UpdateUserDataResult> {
        const url = `${PlayFabClient.apiRoute}/Client/UpdateUserData`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Authorization': login.SessionTicket as string
        });

        const response = firstValueFrom(
            this.http.post<UpdateUserDataResult>(url, request, { headers })
        );

        return response;
    }

    public async addOrUpdateContactEmail(
        request: AddOrUpdateContactEmailRequest,
        login: LoginResult
    ) {
        const url = `${PlayFabClient.apiRoute}/Client/AddOrUpdateContactEmail`;
        const response = firstValueFrom(
            this.http
                .post<PlayFabResponse<AddOrUpdateContactEmailResult>>(url, request, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': login.SessionTicket as string
                    }
                })
                .pipe(map(x => x.data))
        );

        return response;
    }

    public async getPlayerProfile(request: GetPlayerProfileRequest, login: LoginResult) {
        const url = `${PlayFabClient.apiRoute}/Client/GetPlayerProfile`;
        const response = firstValueFrom(
            this.http
                .post<PlayFabResponse<GetPlayerProfileResult>>(url, request, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': login.SessionTicket as string
                    }
                })
                .pipe(map(x => x.data))
        );

        return response;
    }

    public async getUserInventory(
        request: GetUserInventoryRequest,
        login: LoginResult
    ): Promise<GetUserInventoryResult> {
        const url = `${PlayFabClient.apiRoute}/Client/GetUserInventory`;
        const response = firstValueFrom(
            this.http
                .post<PlayFabResponse<GetUserInventoryResult>>(url, request, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': login.SessionTicket as string
                    }
                })
                .pipe(map(x => x.data))
        );

        return response;
    }

    public async getUserData(
        request: GetUserDataRequest,
        login: LoginResult
    ): Promise<GetUserDataResult> {
        const url = `${PlayFabClient.apiRoute}/Client/GetUserData`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Authorization': login.SessionTicket as string
        });

        const response = await firstValueFrom(
            this.http
                .post<PlayFabResponse<GetUserDataResult>>(url, request, {
                    headers
                })
                .pipe(map(x => x.data))
        );

        return response;
    }

    public async getUserReadOnlyData(
        request: GetUserDataRequest,
        login: LoginResult
    ): Promise<GetUserDataResult> {
        const url = `${PlayFabClient.apiRoute}/Client/GetUserReadOnlyData`;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Authorization': login.SessionTicket as string
        });

        const response = await firstValueFrom(
            this.http
                .post<PlayFabResponse<GetUserDataResult>>(url, request, {
                    headers
                })
                .pipe(map(x => x.data))
        );

        return response;
    }

    public async loginWithPlayFab(request: LoginWithPlayFabRequest): Promise<LoginResult> {
        const url = `${PlayFabClient.apiRoute}/Client/LoginWithPlayFab`;
        const response = firstValueFrom(
            this.http
                .post<PlayFabResponse<LoginResult>>(url, request, {
                    headers: { 'Content-Type': 'application/json' }
                })
                .pipe(map(x => x.data))
        );

        return response;
    }

    public async loginWithCustomId(request: LoginWithCustomIdRequest): Promise<LoginResult> {
        const url = `${PlayFabClient.apiRoute}/Client/LoginWithCustomID`;
        const response = firstValueFrom(
            this.http
                .post<PlayFabResponse<LoginResult>>(url, request, {
                    headers: { 'Content-Type': 'application/json' }
                })
                .pipe(map(x => x.data))
        );

        return response;
    }

    public async loginWithEmail(request: LoginWithEmailRequest): Promise<LoginResult> {
        const url = `${PlayFabClient.apiRoute}/Client/LoginWithEmailAddress`;
        const response = firstValueFrom(
            this.http
                .post<PlayFabResponse<LoginResult>>(url, request, {
                    headers: { 'Content-Type': 'application/json' }
                })
                .pipe(map(x => x.data))
        );

        return response;
    }

    public async registerPlayFabUser(request: RegisterPlayFabUserRequest): Promise<LoginResult> {
        const url = `${PlayFabClient.apiRoute}/Client/RegisterPlayFabUser`;
        const response = firstValueFrom(
            this.http
                .post<PlayFabResponse<LoginResult>>(url, request, {
                    headers: { 'Content-Type': 'application/json' }
                })
                .pipe(map(x => x.data))
        );

        return response;
    }

    public async getAccountInfo(
        request: GetAccountInfoRequest,
        login: LoginResult
    ): Promise<GetAccountInfoResult> {
        const url = `${PlayFabClient.apiRoute}/Client/GetAccountInfo`;
        const response = await firstValueFrom(
            this.http.post<PlayFabResponse<GetAccountInfoResult>>(url, request, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': login.SessionTicket as string
                }
            })
        );

        return response.data as GetAccountInfoResult;
    }

    public async getCatalogItems(
        request: GetCatalogItemsRequest,
        login: LoginResult
    ): Promise<GetCatalogItemsResult> {
        const url = `${PlayFabClient.apiRoute}/Client/GetCatalogItems`;
        const response = await firstValueFrom(
            this.http.post<PlayFabResponse<GetCatalogItemsResult>>(url, request, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': login.SessionTicket as string
                }
            })
        );

        return response.data as GetCatalogItemsResult;
    }

    public async getStoreItems(
        request: GetStoreItemsRequest,
        login: LoginResult
    ): Promise<GetStoreItemsResult> {
        const url = `${PlayFabClient.apiRoute}/Client/GetStoreItems`;
        const response = await firstValueFrom(
            this.http.post<PlayFabResponse<GetStoreItemsResult>>(url, request, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': login.SessionTicket as string
                }
            })
        );

        return response.data as GetStoreItemsResult;
    }
}
